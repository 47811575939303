<template>
    <div class="home">
        <v-row justify="space-between">
            <v-col xs="10" md="5">

                <TitleText title="Waar wilt u een afspraak voor maken?"
                           text="Maak een keuze uit de onderstaande opties:"></TitleText>

                <v-row>
                    <v-col>
                        <div v-for="type in types"
                             :key="type.id">
                            <v-btn
                                :color="darkblue"
                                v-if="type.enabled"
                                class="rounded-lg white--text mb-3"
                                width="100%"
                                height="100"
                                @click="nextPage(type.route)"
                            >{{ type.title }}
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-btn
                            color="secondary"
                            type="button"
                            width="100%"
                            height="41px"
                            class="rounded-lg darkgrey--text"
                            @click="previousPage()"
                        >
                            Ga terug
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>

            <v-divider vertical class="d-none d-sm-flex"></v-divider>

            <v-col xs="10" md="5" class="hidden-xs-only">

                <TitleText title="Uw auto"
                           text="Het onderstaande kenteken is aan uw gegevens gekoppeld.<br />Mocht dit niet kloppen
                           kunt u dit wijzigen."></TitleText>

                <CarInfo></CarInfo>

                <CarImage></CarImage>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import CarImage from "@/components/yourcar/CarImage";
import CarInfo from "@/components/yourcar/CarInfo";
import TitleText from "@/components/general/TitelText";

export default {
    "name": "AppointmentType",
    "components": {
        CarImage,
        TitleText,
        CarInfo
    },
    data () {
        return {
            "darkblue": this.$vuetify.theme.themes.light.darkblue,
            "types": [
                {
                    "id": 1,
                    "title": "Onderhoud en reparatie",
                    "route": "MaintenanceAdvice",
                    "enabled": true
                },
                {
                    "id": 2,
                    "title": "Schadeherstel",
                    "route": "DamageRecovery",
                    "enabled": false
                },
                {
                    "id": 3,
                    "title": "Ruitschadeherstel",
                    "route": "WindowDamageRecovery",
                    "enabled": true
                }
            ]
        };
    },
    "methods": {
        previousPage () {
            this.$router.push({"name": "YourCar"});
        },
        nextPage (name) {
            if (this.$store.state.car.invalidLicense && name !== "WindowDamageRecovery") {
                this.$router.push({"name": "MaintenanceAdviceNoOptions"});
            } else {
                this.$store.dispatch(
                    "car/updatePathValue",
                    {
                        "object": "appointmentType",
                        "value": name
                    }
                );
                this.$router.push({name});
            }
        }
    }
};
</script>
