<template>
    <ul class="steps">
        <li class="steps__item steps__item--completed">
            <span class="steps__label">Kenteken</span>

            <span class="steps__seperator" aria-hidden="true">
                <svg class="icon" viewBox="0 0 16 16">
                <polyline fill="none"
                          stroke="currentColor"
                          stroke-width="1"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          points="6.5,3.5 11,8 6.5,12.5 "></polyline>
                </svg>
            </span>

            <div class="steps__circle"></div>
        </li>

        <li :class="`steps__item ${(steps.ACTIVITIES === true ? `steps__item--completed`: ``)}`">
            <span class="steps__label">Werkzaamheden</span>

            <span class="steps__seperator" aria-hidden="true">
                <svg class="icon" viewBox="0 0 16 16">
                <polyline fill="none"
                          stroke="currentColor"
                          stroke-width="1"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          points="6.5,3.5 11,8 6.5,12.5 "></polyline>
                </svg>
            </span>

            <div class="steps__circle"></div>
        </li>

        <li :class="`steps__item ${(steps.LOCATIONTIME === true ? `steps__item--completed`: ``)}`">
            <span class="steps__label">Locatie en tijd</span>

            <span class="steps__seperator" aria-hidden="true">
                <svg class="icon" viewBox="0 0 16 16">
                <polyline fill="none"
                          stroke="currentColor"
                          stroke-width="1"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          points="6.5,3.5 11,8 6.5,12.5 "></polyline>
                </svg>
            </span>

            <div class="steps__circle"></div>
        </li>

        <li :class="`steps__item ${(steps.VEHICLEREPLACEMENT === true ? `steps__item--completed`: ``)}`">
            <span class="steps__label">Vervangend vervoer</span>

            <span class="steps__seperator" aria-hidden="true">
                <svg class="icon" viewBox="0 0 16 16">
                <polyline fill="none"
                          stroke="currentColor"
                          stroke-width="1"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          points="6.5,3.5 11,8 6.5,12.5 "></polyline>
                </svg>
            </span>

            <div class="steps__circle"></div>
        </li>

        <li class="steps__item">
            <span class="steps__label">Samenvatting</span>

            <div class="steps__circle"></div>
        </li>
    </ul>
</template>

<script>

export default {
    "name": "BreadCrumbs",
    "props": ["step"],
    "methods": {
        resetSteps () {
            for (const stp of Object.keys(this.steps)) {
                this.steps[stp] = false;
            }
        }
    },
    created () {
        this.resetSteps();

        let step = "LICENSEPLATE";
        if (typeof (this.step) !== "undefined") {
            step = this.step;
        }
        if (Object.keys(this.steps).find((s) => s === step)) {
            this.currentStep = step;
        }

        for (const stp of Object.keys(this.steps)) {
            if (stp !== this.currentStep) {
                this.steps[stp] = true;
            } else {
                break;
            }
        }
    },
    data () {
        return {
            "currentStep": "",
            "steps": {
                "LICENSEPLATE": false,
                "ACTIVITIES": false,
                "LOCATIONTIME": false,
                "VEHICLEREPLACEMENT": false,
                "SUMMARY": false
            }
        };
    }
};

</script>

<style>

:root {
    --color-blue: #283883;
    --color-black: #000000;
    --color-grey: #DFDFDF;
    --circle-size: 46px;
    --step-separator-line-gap: 0;
    --step-separator-line-stroke: 2px;
    --step-separator-line-gap: 2px;
}

.steps {
    max-width: 1200px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0 !important;
    font-family: inherit;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: flex-start;
}

.steps__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: calc(100% / 5);
}

.steps__circle {
    position: relative;
    width: var(--circle-size);
    height: var(--circle-size);
    z-index: 2;
    border-radius: 50%;
    background-color: var(--color-grey);
    margin-bottom: 10px;
    order: 0;
}

.steps__circle::before {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--color-blue);
}

.steps__label {
    text-transform: uppercase;
    color: var(--color-black);
    order: 2;
}

.steps__seperator {
    position: absolute;
    top: calc(var(--circle-size) / 2 - 2px);
    left: 50%;
    height: var(--step-separator-line-stroke);
    width: 100%;
    margin: 0;
    background-color: var(--color-grey);
    color: transparent;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    transition: 300ms all;
    display: inline-block;
    vertical-align: middle;
}

.steps__item--completed .steps__seperator {
    background-color: var(--color-blue);
}


.attribution {
    font-size: 11px;
    text-align: center;
}

.attribution a {
    color: hsl(228, 45%, 44%);
}
</style>
