<template>
    <v-app>
        <v-main>
            <v-container>

                    <v-img :src="logo" width="300" height="60" contain
                       class="ma-auto" style="cursor: pointer;" @click="resetAndGoToHome()"></v-img>

                <v-row>
                    <v-col sm="12" md="12">
                        <router-view/>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>

import logo from "./../static/img/logo.svg";

export default {
    "name": "App",
    "methods": {
        isLoggedIn () {
            return localStorage.getItem("jwt") !== null;
        },
        hideSnackbar () {
            this.$store.dispatch(
                "snackbar/setShow",
                false
            );
            return true;
        },
        async reset () {
            await this.$store.dispatch("car/resetState");
        },
        async resetAndGoToHome () {
            await this.reset();
            this.$router.push({"name": "Home"});
        }
    },
    async created () {
        window.addEventListener(
            "beforeunload",
            this.reset
        );
        if (this.$router.currentRoute.name !== "Home") {
            this.$router.push({"name": "Home"});
        }

        await this.$store.dispatch("cms/getTexts");
    },
    data () {
        return {
            logo,
            "items": [
                {"title": "Dashboard",
                    "link": "/",
                    "icon": "mdi-view-dashboard"},
                {"title": "Producten",
                    "link": "/products",
                    "icon": "mdi-image"},
                {"title": "Monteurs",
                    "link": "/mechanics",
                    "icon": "mdi-account"},
                {"title": "Uitloggen",
                    "link": "/logout",
                    "icon": "mdi-logout"}
            ],
            "right": null
        };
    },
    "computed": {
        "showSnackbar": {
            get () {
                return this.$store.state.snackbar.show;
            },
            set (newValue) {
                this.$store.dispatch(
                    "snackbar/setShow",
                    newValue
                );
            }
        }
    },
    "watch": {
        "$route": {
            "immediate": true,
            handler (to) {
                const pageTitlePrefix = "Werkplaatsplanner - ";
                document.title = pageTitlePrefix + (to.meta.title || "");
            }
        }
    }
};
</script>
<style lang="scss">
@import "scss/_bootstrap-defaults.scss";
@import "scss/app.scss";
</style>
