<template>
    <div class="maintenanceAdvicePage">
        <v-row justify="space-between">
            <v-col xs="10" md="5">

                <TitleText title="Wilt u een onderhoudsadvies"
                           :text="text('maintenanceAdvice')"></TitleText>

                <v-row>
                    <v-col>
                        <v-btn
                            :color="darkblue"
                            class="rounded-lg white--text mb-3"
                            width="100%"
                            height="100"
                            @click="nextPage(true)"
                        >Ja, ik wil een onderhoudsadvies
                        </v-btn>

                        <v-btn
                            :color="orange"
                            class="rounded-lg darkblue--text mb-3"
                            width="100%"
                            height="100"
                            @click="nextPage(false)"
                        >Nee, ik wil geen onderhoudsadvies
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-btn
                            color="secondary"
                            type="button"
                            width="100%"
                            height="41px"
                            class="rounded-lg darkgrey--text"
                            @click="previousPage()"
                        >
                            Ga terug
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>

            <v-divider vertical class="d-none d-sm-flex"></v-divider>

            <v-col md="5" class="hidden-xs-only">

                <TitleText title="Uw auto"
                           text="Het onderstaande kenteken is aan uw gegevens gekoppeld.<br />Mocht dit niet kloppen
                           kunt u dit wijzigen."></TitleText>

                <CarInfo></CarInfo>

                <CarImage></CarImage>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import CarImage from "@/components/yourcar/CarImage";
import CarInfo from "@/components/yourcar/CarInfo";
import TitleText from "@/components/general/TitelText";
import {mapGetters} from "vuex";
import moment from "moment";

export default {
    "name": "MaintenanceAdvice",
    "components": {
        CarImage,
        TitleText,
        CarInfo
    },
    data () {
        return {
            "darkblue": this.$vuetify.theme.themes.light.darkblue,
            "orange": this.$vuetify.theme.themes.light.orange
        };
    },
    "computed": {
        ...mapGetters({
            "text": "cms/getTextByName"
        })
    },
    "methods": {
        // eslint-disable-next-line max-lines-per-function,max-statements
        async nextPage (doAdvice) {
            let changeAdvice = doAdvice;
            const dateCheck = moment(this.$store.state.car.currentCarInfo.modelYear);
            if (!dateCheck.isValid()) {
                changeAdvice = false;
            }

            await this.$store.dispatch(
                "car/updatePathValue",
                {
                    "object": "doAdvice",
                    "value": changeAdvice
                }
            );
            const filter = {
                "advice": changeAdvice,
                "make": this.$store.state.car.currentCarInfo.make,
                "modelId": this.$store.state.car.currentCarInfo.modelId,
                "modelYear": this.$store.state.car.currentCarInfo.modelYear,
                "motorName": this.$store.state.car.currentCarInfo.motorName,
                "fuelType": this.$store.state.car.currentCarInfo.fuelType,
                "transmission": this.$store.state.car.currentCarInfo.transmission,
                "licensePlate": this.$store.state.car.Appointment.Fields.RegistrationNumber,
                "apkExpireDate": this.$store.state.car.currentRdwData.vervaldatum_apk_dt,
                "km": parseInt(
                    this.$store.state.car.Appointment.Fields.Mileage,
                    10
                )
            };
            await this.$store.dispatch(
                "car/getMaintenanceProducts",
                filter
            );
            await this.$store.dispatch(
                "car/getActionProducts",
                filter
            );
            if (changeAdvice && dateCheck.isValid()) {
                this.$router.push({"name": "MaintenanceAdviceOptions"});
            } else {
                await this.$router.push({"name": "MaintenanceAdviceNoOptions"});
            }
        },
        previousPage () {
            this.$router.push({"name": "AppointmentType"});
        }
    }
};
</script>
