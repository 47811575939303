<template>
    <div>
        <v-row>
            <v-col>
                <v-sheet flat>
                    <v-progress-linear
                        v-if="unAvailableDates.length === 0"
                        indeterminate
                        color="primary"
                    ></v-progress-linear>

                    <VueCtkDateTimePicker
                        color="#283883"
                        button-color="#283883"
                        :inline="true"
                        :only-date="true"
                        :max-date="maxDate"
                        :min-date="minDate"
                        :no-button-now="true"
                        :disabled-dates="unAvailableDates"
                        :disabled="unAvailableDates.length === 0"
                        :format="'YYYY-MM-DD'"
                        v-model="maintenanceDate"
                    ></VueCtkDateTimePicker>
                </v-sheet>
            </v-col>
        </v-row>
        <v-row>
            <v-col>

                <v-progress-linear
                    v-if="maintenanceDate && times.length === 0"
                    indeterminate
                    color="primary"
                ></v-progress-linear>
                <v-btn-toggle group class="multiplerow-flex" v-model="maintenanceTime" mandatory>
                    <v-btn
                        v-for="time in times"
                        :key="time.Time"
                        :disabled="time.Available === 0"
                        color="primary"
                        :value="time.Time"
                        @click="nextPage(time.Time)"
                        text
                    >{{ time.Time }}
                    </v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import moment from "moment";

export default {
    "name": "Planner",
    "props": {
        "nextRoute": {
            "type": String,
            "default": "ContactInfo"
        }
    },
    data () {
        return {
            "minDate": moment().format("YYYY-MM-DD")
        };
    },
    async created () {
        await this.$store.dispatch("car/getDisabledDates");
        await this.$store.dispatch("car/getFirstAvailablePlanDate");
    },
    "methods": {
        updatePathValue (object, value) {
            this.$store.dispatch(
                "car/updatePathValue",
                {
                    object,
                    value
                }
            );
        },
        nextPage (time) {
            this.updatePathValue(
                "currentTime",
                time
            );
            if (this.nextRoute) {
                this.$router.push({"name": this.nextRoute});
            }
        }
    },
    "computed": {
        unAvailableDates () {
            return this.$store.state.car.currentDisabledDates;
        },
        maxDate () {
            const maxSelectableDate = this.$store.state.car.maxSelectableDate;

            if (maxSelectableDate) {
                return maxSelectableDate.format("YYYY-MM-DD");
            }

            return "";
        },
        times () {
            return this.$store.state.car.currentTimes;
        },
        "maintenanceDate": {
            get () {
                return this.$store.state.car.maintenanceDate;
            },
            set (value) {
                this.updatePathValue(
                    "maintenanceDate",
                    value
                );
                this.updatePathValue(
                    "Appointment.AppointmentInfo.Date",
                    value
                );
                this.maintenanceTime = null;
            }
        },
        "maintenanceTime": {
            get () {
                return this.$store.state.car.maintenanceTime;
            },
            set (value) {
                this.updatePathValue(
                    "maintenanceTime",
                    value
                );
                this.updatePathValue(
                    "Appointment.AppointmentInfo.Time",
                    value
                );
                if (value !== null) {
                    this.updatePathValue(
                        "Appointment.AppointmentInfo.Date",
                        moment(
                            `${this.maintenanceDate} ${value}`,
                            "YYYY-MM-DD hh:mm"
                        ).format()
                    );
                }
            }
        }
    },
    "watch": {
        async maintenanceDate (date) {
            if (date) {
                await this.$store.dispatch(
                    "car/getTimes",
                    moment(date).format("YYYY-MM-DD")
                );
            }
        }
    }
};

</script>

<style>

@import "~vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

.multiplerow-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    /* You can set flex-wrap and
       flex-direction individually */
    flex-direction: row;
    flex-wrap: wrap;
    /* Or do it all in one line
      with flex flow */
    flex-flow: row wrap;
    /* tweak where items line
       up on the row
       valid values are: flex-start,
       flex-end, space-between,
       space-around, stretch */
    align-content: flex-end;
}

.theme--light.v-btn--active:hover:before {
    opacity: 0.08 !important;
}

.theme--light.v-btn--active:before {
  opacity: 0 !important;
}

.theme--light.v-btn--active {
    opacity: 0.8 !important;
}


</style>
